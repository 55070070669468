aside {
  background-color: rgb(35, 125, 7) !important;
}

.ant-select-dropdown {
  min-width: 240px;
}

/* Nishitha */
.MuiDataGrid-cell {
  display: flex;
  align-items: center;
}
.wrapper {
  background-image: url("./../public/assets/images/bgpanel.jpg");
  background-size: cover;
}
:where(.css-dev-only-do-not-override-kghr11).ant-input-outlined{
  border:1px solid #000066 !important;
  color:#000066 !important;
}
.ant-col{
  color:#000066 !important;
}
:where(.css-dev-only-do-not-override-kghr11).ant-input:placeholder-shown {
  color:#000066 !important;
}
#demoq {
  border-radius: 5px;
  background-color: #ff407d;
  border-color: #ff407d;
}

.MuiDataGrid-cellContent {
  white-space: normal;
  word-wrap: break-word;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
}

/* Nishitha */
.load {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-menu {
  background: transparent !important;
}

.imagecontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 40%;
}

.imageBox {
  position: relative;
}

.imgremBtn {
  position: absolute;
  top: 0;
  right: 1vh;
  height: 4vh;
  width: 4vh;
  background-color: #ff9999;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.error_class {
  border-color: #ff9999;
  background: #ffe6e6;
}

.loadingMain {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* =================login form======================== */
.loginCont {
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.loginMain {
  width: 40%;
  height: 60%;
  background-color: white;
  margin: auto;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginPage {
  width: 60%;
}

.loginPage h2 {
  text-align: center;
  margin: 10px;
}

.loginPage dt {
  margin-bottom: 10px;
}

.loginPage .input {
  width: 80%;
  padding: 10px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #9cd129;
}

.loginBtn {
  margin-top: 10%;
}

.loginBtn button {
  padding: 2%;
  width: 40%;
  border: none;
  background: #9cd129;
  color: white;
  border-radius: 10px;
  outline: transparent;
}

.loginBtn button:hover {
  background-color: black;
}

/*================================= Add Drugs ====================*/
.AddContainer {
  background: #f4f6f9;
  padding: 5px;
}

.AddContainer h2 {
  margin-bottom: 40px;
  margin-left: 20px;
}

/*============================== Add products======================= */

.productContainer {
  background: #f4f6f9;
  padding: 5px;
}

.productContainer h2 {
  margin-bottom: 40px;
  margin-left: 20px;
}

.checkbocProduct {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.checkbocProduct input {
  margin: 10px;
}

.categorytag {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.categorytag input {
  margin: 10px;
  padding: 2px;
}

.errorCont {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* ==========================nav hover========================== */
nav ul li p {
  font-size: 16px;
  font-weight: 500;
}

.nav-link span {
  font-size: 20px;
  margin-right: 5px;
}

.arrowsize {
  margin-top: 7px !important;
}

.brand-text {
  font-size: 22px;
  font-weight: 600 !important;
}

.headerDesign {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  height: 100%;
}

.headerDesign li {
  list-style: none;
  margin-right: 20px;
}

.headerDesign li span {
  font-size: 25px;
  cursor: pointer;
  color: #051937;
}

.productDesign {
  margin-left: 15px;
}

.kjhTags {
  margin-left: 10px;
}

.errorhandle {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.errorlabel {
  color: tomato;
  margin-top: -5px;
  font-size: 12px;
}

.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box1 {
  width: 100%;
  background: rgba(0, 0, 0, 0);
}

.box2 {
  width: 100%;
  background: rgba(0, 0, 0, 0);
}

.aguserid {
  background: #00bf72;
  height: 7vh;
  border-radius: 4px;
}

.ageidbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #f4f6f9;
}

.agedivigm {
  margin-left: 35%;
  text-align: center;
}

.agedivigm .agentimg {
  width: 30vh;
  background: #f8f9fa;
  height: 30vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.agentimg img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

body::-webkit-scrollbar {
  display: none !important;
}

/* ============================ media query =========================*/
@media screen and (max-width: 1400px) {
  .loginPage {
    width: 75%;
  }

  .loginMain {
    width: 45%;
  }
}

@media screen and (max-width: 1200px) {
  .loginPage {
    width: 70%;
  }

  .loginMain {
    width: 42%;
  }
}

@media screen and (max-width: 1000px) {
  .loginPage {
    width: 70%;
  }

  .loginMain {
    width: 55%;
  }
}

@media screen and (max-width: 800px) {
  .loginPage {
    width: 85%;
  }

  .col_fordesktop {
    display: none !important;
  }

  .loginMain {
    width: 65%;
  }

  .categoryMain .categoryMain1 {
    width: 95%;
  }

  .categoryMain .categoryMain2 {
    width: 95%;
  }
}

@media screen and (max-width: 500px) {
  .loginPage {
    width: 90%;
  }

  .col_fordesktop {
    display: none !important;
  }

  .loginMain {
    width: 90%;
  }

  .categoryMain .categoryMain1 {
    width: 95%;
  }

  .categoryMain .categoryMain2 {
    width: 95%;
  }

  .ageinfo {
    flex-direction: column;
  }
}

@media screen and (max-width: 350px) {
  .loginPage {
    width: 80%;
  }

  .col_fordesktop {
    display: none !important;
  }

  .loginMain {
    width: 90%;
  }

  .ageinfo {
    flex-direction: column;
  }
}

/* Nishitha code  */
.os-content {
  background-color: #000066 !important;
}

.col_fordesktop {
  display: block !important;
}

.css-ptiqhd-MuiSvgIcon-root {
  color: #ffff !important;
}

.css-1iyq7zh-MuiDataGrid-columnHeaders {
  background-color: #0c359e !important;
}

.customsheader {
  background-color: #0c359e !important;
  color: #ffff !important;
}

.form-control {
  border-color: #000066 !important;
}

.form-control:hover {
  border-color: #00b33c !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover {
  border-color: #00b33c !important;
  color: #00b33c !important;
}

:where(
    .css-dev-only-do-not-override-kghr11
  ).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-kghr11).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-inline,
:where(
    .css-dev-only-do-not-override-kghr11
  ).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-kghr11).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-vertical {
  background-color: #000066 !important;
  color: white !important;
}

.main-sidebar {
  background-color: #000066 !important;
}

.ant-menu-item {
  color: #ffff !important;
}
label:not(.form-check-label):not(.custom-file-label) {
color:#000066 !important

}

.ant-menu-item-selected {
  color: #000066 !important;
  background-color: white !important;
}

.ant-menu-submenu {
  color: #ffff !important;
}

.ant-menu-submenu-title {
  color: #ffff !important;
}

.form-select {
  border: 1px solid #000066 !important;
}

.form-select::placeholder {
  color: #000066 !important;
}

.form-select:hover {
  border-color: #00b33c !important;
}

.form-select:focus {
  border-color: #00b33c !important;
}

.textfield {
  width: 100% !important;
}

.textfieldcontainer {
  /* margin-top: 7px !important; */
  width: 100%;
}

.space_another {
  margin-left: 0.9rem !important;
  margin-top: 0.9rem !important;
}

.container_down {
  margin-top: 1.7rem !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 12px !important;
  color: #000066 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #000066 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #000066 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  height: 6px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 6px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.5rem !important;
  /* background-color: red !important; */
  border-radius: 0.5rem !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:hover {
  border-color: #00b33c !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  border-color: #00b33c !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder:hover {
  border-color: #00b33c !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder:focus {
  border-color: #00b33c !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: black !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  border-color: #00b33c !important;
}

.textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #00b33c !important;
  border-width: 0.3px;
}

.textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00b33c !important;
  border-width: 0.3px;
  /* border-bottom-color:  #0e947a; */
}

/* .textfield .MuiOutlinedInput-root:hover .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #47d147 !important;
} */

/* .textfield .MuiOutlinedInput-root.Mui-focused .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #47d147 !important;
} */

/* .textfield .MuiInputLabel-root.Mui-focused {
  color: #00b33c !important;
} */

.dashboard-back:hover {
  box-shadow: rgba(77, 68, 181, 0.2) 0px 10px 10px 0px;
}

.form-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 70px;
}

.css-1x5jdmq {
  height: 38px !important;
  padding: 0px 5px !important;
}

.css-qiwgdb.MuiSelect-select {
  min-height: 5px !important;
  height: 5px !important;
}

.m-4 {
  margin: 0.5rem 1.5rem 1.5rem 1.5rem !important;
}

/* .main-header{
  background-color: #00324d !important;
  color :#ffff !important;
}
.headerDesign li span{
  color :#ffff !important;
}
.navbar-light .navbar-nav .nav-link{
  color :#fff !important;
} */

:where(.css-dev-only-do-not-override-kghr11).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid #000066 !important;
}

:where(.css-dev-only-do-not-override-kghr11).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector:hover {
  border: 1px solid #00b33c !important;
}

:where(.css-dev-only-do-not-override-kghr11).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector:focus {
  border: 1px solid #00b33c !important;
}

.ant-select-selection-placeholder {
  color: #000066 !important;
}

.ant-select-selection-placeholder:hover {
  color: #00b33c !important;
}

.ant-select-selection-placeholder:focus {
  color: #00b33c !important;
}

.ant-select-arrow {
  color: #000066 !important;
}

.ant-select-arrow:hover {
  color: #00b33c !important;
}

.ant-select-arrow:focus {
  color: #00b33c !important;
}

.ant-col {
  color: rgb(0, 0, 102) !important;
}

.ant-col:hover {
  color: #00b33c !important;
}

.ant-col:focus {
  color: #00b33c !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root:focus {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.form-check-input:checked {
  background-color: #3fa2f6 !important;
  border-color: #3fa2f6 !important;
}

.editbuttonnn {
  background-color: rgb(69, 184, 69) !important;
  border-color: rgb(69, 184, 69) !important;
}

.deletebuttomss {
  background-color: #fa7070 !important;
  border-color: #fa7070 !important;
}

.toaster_addd {
  z-index: 99999999 !important;
}
